import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import ChatIcon from "../../../assets/chat-icon.svg"
import LinkedinIcon from "../../../assets/linkedin-leadership.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const RecuitersTeamSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{
        background: "linear-gradient(#EFF2F4, #EFF2F4)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartDarkCircleIcon />
        </div>

        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#333333" }}
        >
          Norhart Team
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#365c70" }}>
          Connect.&nbsp;&nbsp;Interview.&nbsp;&nbsp;Hired
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.8" }}>
          We are looking for extraordinary hires! If you think you have what it takes to help us disrupt the multi-home
          industry, contact and connect with us so we can chat! #TeamNorhart
        </h4>

        <div className="container mt-5">
          <div className="row">
            <div className="col-sm mt-5">
              <div className="ibox-content product-box bct-3 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/recruiter-team/norhart-paula-gelati.png"
                      alt="Paula Gelati"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Recruiter</small>
                  <span className="product-name">Paula Gelati</span>
                  <div className="small m-t-xs">Talent Acquisition Lead</div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/paula-gelati-46662252/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm mt-5">
              <div className="ibox-content product-box bct-3 animate__animated animate__slideOutUp">
                <div className="ibox">
                  <div>
                    <img
                      src="/recruiter-team/norhart-floremcia-hasman.png"
                      alt="Florencia Hasman, Talent Acquisition Specialist"
                      loading="lazy"
                      decoding="async"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="product-desc">
                  <small className="text-muted">Norhart Recruiter</small>
                  <span className="product-name">Florencia Hasman</span>
                  <div className="small m-t-xs">Talent Acquisition Specialist</div>
                  <div className="small m-t-xs mt-4">
                    <a href="https://www.linkedin.com/in/florencia-hasman-3301751b1/" target="_blank">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default RecuitersTeamSection
