import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import RecuitersTeamSection from "../components/static-sections/RecuitersTeamSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import RickRollNorhartLife from "../components/dynamic-sections/RickRollNorhartLife"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import GalleryImageLargeSection from "../components/dynamic-sections/GalleryImageLargeSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import SearchIcon from "../../assets/search-icon.svg"
import ApartmentIcon from "../../assets/apartment-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const RecruiterTeamPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/recruiter-team/",
          name: "Recruiting | Norhart",
          image: `${config.siteMetadata.siteUrl}/recruiter-team/norhart-recruiters-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Recruiting | Norhart"
      description="Norhart builds and rents exceptional luxury apartments in Forest Lake, Blaine, Circle Pines, Lexington, and Oakdale, Minnesota. We love creating a better way for people to live!"
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/recruiter-team/norhart-recruiters-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/recruiter-team/norhart-recruiters-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Recruiting Team"
        subtitle="Connect With The Norhart Team"
        imageTitle="Norhart Recruiting Team"
        image="/recruiter-team/norhart-recruiters-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection title="Get Hired" tagLine="Were looking for extraordinary people" colorPalette={colorPalette} />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <RecuitersTeamSection colorPalette={colorPalette} />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={SearchIcon}
        iconTitle="Norhart"
        title="We Are Searching For You"
        subTitle="Kill the interview, align with our values, and be the perfect fit for the role, and you will be one of us! Your future colleagues are looking forward to working with you! #TeamNorhart #FistBump"
        image="/interview-process/norhart-interview-process-be-one-of-us.png"
        imageAlt="Join The Norhart Crew"
        buttonName="Interview Process"
        buttonUrl="/interview-process/"
        textColor="#FFFFFF"
        asoEffect="zoom-in"
      />

      <HeroBottomSection
        backgroundColorOne="#F0F0F0"
        backgroundColorTwo="#F0F0F0"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"Norhart is committed to being the best. Our construction team is made up of great people! From top to bottom everyone is continually looking for ways to get even better at what they do. Our job site is a fun and respectful place for people to work." - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Now Hiring"
        title="Yes! Be One Of Us!"
        subTitle="We are spreading the word in the Minneapolis/St. Paul Metro area that Norhart is hiring individuals who want to change the world one apartment at a time! Should you apply? #HellYeah"
        image="/careers/norhart-careers-construction-site-sign.png"
        imageAlt="Join The Norhart Crew"
        textColor="#FFFFFF"
        buttonName="Search Norhart Jobs"
        buttonUrl="/careers/"
      />

      <RickRollNorhartLife
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="#NorhartCuture"
        title="The Norhart Mindset"
        image="/norhart-life/norhart-team-mindset.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart"
        title="Welcome To The Team!"
        subTitle="Kill the interview, align with our values, and be the perfect fit for the role, and you will be one of us! Your future colleagues are looking forward to working with you! #TeamNorhart #FistBump"
        image="/interview-process/norhart-interview-process-be-one-of-us.png"
        imageAlt="Join The Norhart Crew"
        textColor="#FFFFFF"
        asoEffect="zoom-in"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default RecruiterTeamPage
